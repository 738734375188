import { EcawinEvent } from './ecawin-event';
import { LiteConvocation } from './lite-convocation';

export class Convocation extends LiteConvocation {
  ecawinEvent: EcawinEvent;

  constructor(json?: any) {
    super(json);
    if (json) {
      this.ecawinEvent = new EcawinEvent(json.ecawinEvent);
    }
  }
}
