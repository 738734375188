export const RANK_MAPPING = {
  Col: 16,
  'Lt-Col': 15,
  Maj: 14,
  Cap: 13,
  Plt: 12,
  Lt: 11,
  'Adj sof': 10,
  Sgtm: 9,
  Four: 8,
  'Sgt chef': 7,
  Sgt: 6,
  Cpl: 5,
  'App chef': 4,
  App: 3,
  Sap: 2,
  Rec: 1,
  Asp: 1,
  null: 0
};
