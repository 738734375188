export class LiteEcawinEvent {
  id: number;

  summary: string;
  note1: string;
  note2: string;
  ecawinCode: string;
  location: string;
  dateStart: Date;
  dateEnd: Date;

  convocationCount: number;

  constructor(json?: any, convocationCount?: number) {
    if (json) {
      this.id = json.id;
      this.summary = json.summary;
      this.note1 = json.note1;
      this.note2 = json.note2;
      this.ecawinCode = json.ecawinCode;
      this.location = json.location;
      this.dateStart = new Date(json.dateStart);
      this.dateEnd = new Date(json.dateEnd);
    }
    this.convocationCount = convocationCount;
  }
}
