import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Convocation} from '../model/convocation';
import {EcawinConvocation} from '../model/ecawin-convocation';
import {EcawinEvent} from '../model/ecawin-event';

@Injectable({
  providedIn: 'root'
})
export class ConvocationService {
  constructor(private http: HttpClient) {}

  getConvocations() {
    return this.http
      .get('/api/admin/convocations')
      .pipe(map((value: any[]) => value.map(t => new EcawinConvocation(t))));
  }

  sendConvoc(u: string) {
    // @ts-ignore
    return this.http
      .post('/api/admin/convocations', u)
      .pipe(map((value: any) => new EcawinConvocation(value)));
  }

  sendAllConvocs() {
    return this.http.post('/api/admin/convocations/do-it-all', 'data-for-post');
  }

  sendAllConvocsWithoutMail() {
    return this.http.post(
      '/api/admin/convocations/do-it-all-no-mail',
      'data-for-post'
    );
  }

  getMyConvocations(max: number) {
    let maxParam = '';
    if (max) {
      maxParam = '?max=' + max;
    }
    return this.http
      .get('/api/convocations/my' + maxParam)
      .pipe(map((value: any[]) => value.map(t => new Convocation(t))));
  }

  getMyPermanences(max: number) {
    let maxParam = '';
    if (max) {
      maxParam = '?max=' + max;
    }
    return this.http
      .get('/api/convocations/myPermanences' + maxParam)
      .pipe(map((value: any[]) => value));
  }

  upload() {
    return this.http
      .post('/api/admin/convocations/upload', null)
      .pipe(map((value: any[]) => value.map(t => new EcawinConvocation(t))));
  }

  absence(convocation: Convocation, reason: string) {
    return this.http
      .post('/api/convocations/absence/' + convocation.id, reason)
      .pipe(map((value: any) => new Convocation(value)));
  }

  deleteAllConvocations() {
    return this.http.delete('/api/admin/convocations/purge');
  }

  addConvoc(filename: string) {
    return this.http
      .post('/api/admin/convocations/no-mail', filename)
      .pipe(map((value: any) => new EcawinConvocation(value)));
  }

  findCoursAndExercices() {
    return this.http
      .get('/api/admin/convocations/ecawinevent')
      .pipe(
        map((value: any[]) =>
          value.map(t => new EcawinEvent(t.event, t.convocationCount))
        )
      );
  }

  loadEcawinEvent(c: EcawinEvent) {
    return this.http
      .get('/api/admin/convocations/ecawinevent/' + c.id)
      .pipe(map(value => new EcawinEvent(value)));
  }

  deletEcawinEvent(c: EcawinEvent) {
    return this.http.delete('/api/admin/convocations/ecawinevent/' + c.id);
  }
}
