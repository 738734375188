import {LiteUser} from '../plugin-roulage/model/lite-user';

export class LiteConvocation {
  id: number;

  user: LiteUser;
  absenceDate: Date;
  absenceReason: string;

  constructor(json?: any) {
    if (json) {
      this.id = json.id;
      if (json.user) {
        this.user = new LiteUser(json.user);
      }
      this.absenceDate = json.absenceDate;
      this.absenceReason = json.absenceReason;
    }
  }
}
