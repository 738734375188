import { Convocation } from './convocation';
import { LiteConvocation } from './lite-convocation';
import { LiteEcawinEvent } from './lite-ecawin-event';
import { RANK_MAPPING } from './rank';

export class EcawinEvent extends LiteEcawinEvent {
  convocations: Convocation[];

  constructor(json?: any, count?: number) {
    super(json, count);
    if (json) {
      const convocations: any[] = json.convocations;
      if (convocations) {
        this.convocations = json.convocations.map(v => new LiteConvocation(v));
        this.convocations = this.convocations.sort(
          (a, b) => RANK_MAPPING[b.user.rank] - RANK_MAPPING[a.user.rank]
        );
        this.convocationCount = this.convocations.length;
      }
    }
  }
}
