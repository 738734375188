export class EcawinConvocation {
  filename: string;
  uploadedAt: Date;
  convocatedAt: Date;
  deliveredAt: Date;
  openedAt: Date;
  clickedAt: Date;

  constructor(json?: any) {
    if (json) {
      this.filename = json.filename;
      this.uploadedAt = json.uploadedAt;
      this.convocatedAt = json.convocatedAt;
      this.deliveredAt = json.deliveredAt;
      this.openedAt = json.openedAt;
      this.clickedAt = json.clickedAt;
    }
  }
}
